.app-header {
  background-color: #232f3e;
  height: 'auto';

  > nav {
    height: 40px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #232f3e;
    margin: 0 auto;
    &.aligned {
      max-width: 1525px;
      min-width: 1280px;
      padding: 5px 30px;
    }
    > h4 {
      color: #ff6f00;
      font-size: 26px;
      margin: auto;
      padding: 0;
    }
    .logo-link {
      > img {
        width: 120px;
        max-height: 40px;
      }
    }
    .help-icon {
      > img {
        width: 35px;
        padding-left: 10px;
      }
      .hide
      {
        display:none;
      }
    }
    .right {
      .padded-item {
        padding: 0.25rem 1.5rem;
      }
      .languages {
        padding: 0.25rem 1.5rem;
        a {
          color: #c4c4c4;
          &.active,
          &:hover {
            color: #ff6f00;
          }
          margin: 5px;
        }
      }
    }
    .dropdown-toggle {
      padding-bottom: 0;
      padding-top: 0;
    }
    .copyright {
      word-break: break-word;
      a {
        color: #ff6f00;
        cursor: pointer;
      }
    }
  }

  .home_360doc {
    display: none;
  }
}
