@import '../mixins/_images-bg.scss';

.observation-search {
  .row {
    align-items: center;
  }
}

.ucr-details {
  background-color: #f6f8fb;
  border: 0px;
  display: flex;
  height: 100%;

  .full-height {
    height: 100%;
  }

  .related-uc-section {
    flex: 0 0 calc(29% - 5px);
    margin-right: 5px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 3px;

    .disabled-exemption-label {
      color: grey;
      margin-top: 5px;
      height: 5px;
      font-weight: 400;
      text-align: center;
      font-size: 14px;
    }
  }

  .ucr-form-section {
    flex: 0 0 71%;
  }

  .image-section {
    flex: 0 0 calc(55% - 5px);
    max-width: 742px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 3px;
  }

  .list-container {
    margin: 2px;
    height: 69%;
    overflow-y: auto;
    .nav-item a {
      padding: 8px 10px;
    }
  }
  .observation-actions {
    height: 12%;
    display: flex;
    align-items: center;
    button {
      width: 100%;
    }
    .row {
      width: 100%;
      > div {
        padding: 5px;
      }
    }
  }
  .right-container {
    height: 100%;
    margin-left: 5px;
  }
  .repeat-alerts {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    padding: 5px 0;
    > div {
      flex: 1;
      height: 100%;
      padding: 0.2rem 0.5rem;
    }
    div:first-child {
      margin-right: 5px;
    }
  }
  > .row {
    width: 100%;
  }
  i {
    cursor: pointer;
  }
}

.observation-relation-control {
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button,
  a {
    flex: 1;
    min-width: 120px;
    max-width: 190px;
    margin: 0 3px;
  }
  button:last-child {
    margin-right: 0;
  }
}

.related-observations-list {
  height: calc(100% - 105px);
  > ul {
    height: 98%;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    border-bottom: 1px solid #eceeef;
    &:hover {
      overflow-y: auto;
    }
  }
}

.related-observation-listitem {
  display: flex;

  .related-observation-listitem-icon {
    flex: 1;
  }
  .related-observation-listitem-text-short {
    flex: 2;
  }
  .related-observation-listitem-text-long {
    flex: 5;
    white-space: nowrap;
  }
  span {
    font-size: 0.825rem;
  }
  .text-centered {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
}

.observation-images {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1px;

  .observation-image {
    @include images-container;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}

.observation-panorama-container {
  background-color: #eceeef;
  border-right: 1px solid #fff;
  height: 100px;

  p {
    color: #fd6e1d;
    line-height: 30px;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  .observation-panorama-images {
    height: 70px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px 10px;
    white-space: nowrap;

    .observation-image {
      @include images-container;
      display: inline-block;
      height: 100%;
      margin-right: 10px;
      width: 60px;
    }
  }
}

.offense-card {
  height: 100%;
  overflow-x: hidden;
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 9px;

  &.cop {
    height: 49%;
  }

  .offense-card-exemption-container {
    text-align: right;
  }
  .offense-read-only-fields {
    > div {
      display: flex;
    }
    span {
      flex: 1;
      font-size: 0.75rem;
      margin-bottom: 5px;
      &:last-child {
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}

.tabbed-panel-card {
  height: 49%;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 9px;

  .col-1 {
    flex: 0 0 6.73333%;
    max-width: 6.73333%;
  }

  .col-2 {
    flex: 0 0 15.33333%;
    max-width: 15.33333%;
  }

  .col-5 {
    flex: 0 0 46.33333%;
    max-width: 46.33333%;
  }

  .tabbed-panel-nav {
    border-left: 1px solid #ff6f00;

    &.col-sm-1 {
      flex: 0 0 5.33333%;
      max-width: 5.33333%;
    }

    .nav-link {
      text-align: center;
      color: #4a4a49;

      padding: 0.2rem;

      &.active,
      &.active:hover {
        background: #ffa677;
      }

      &:hover {
        background: #e9c7b4;
      }

      &.logs {
        color: #ffffff;
        background: #979797;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }

  .tabbed-panel-content {
    height: 100%;
    font-size: 0.8rem;
    padding: 5px 10px;

    &.col-sm-11 {
      flex: 0 0 94.66667%;
      max-width: 94.66667%;
    }

    .overflow-list {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .tabbed-panel-list-header {
      div {
        font-weight: bold;
        background-color: #FFFFFF;
      }
    }

    .tabbed-panel-list-row {
      div {
        background-color: #FFFFFF;
      }
    }

    .sticky-top {
      z-index: 990;
    }
  }
}

.ucr-form {
  .ucr-close {
    position: absolute;
    right: -10px;
    top: 0px;
    z-index: 1000;
  }

  .ucr-form-offenses {
    .repeat-offender-button {
      button {
        border-radius: 5px;
        color: #212529;
        height: 40px;
        padding: 0 5px;
        width: 100%;
        line-height: 1.25em;

        &.btn-danger {
          background-color: #efdfde;
        }
        &.btn-warning {
          background-color: #f9f1d0;
        }
      }
    }
  }
  .form-label {
    font-size: 0.8em;
    line-height: 1;
  }
  .form-group {
    margin-bottom: 5px;

    select:enabled,
    input:enabled {
      background-color: #fdfce5;
    }
  }
  .col-with-info {
    display: flex;
    align-items: center;
    .info-icon {
      align-self: center;
      margin-top: 15px;
      color: #6A6969;
    }
  }
  .offense-code {
    flex-wrap: wrap;
    &.offense-code-alert {
      input {
        background-color: #ff6f00;
      }
    }
    label {
      width: 100%;
    }
    .dropdown {
      width: 95%;
    }
    .form-text {
      margin-top: 5px;
    }
  }
  .custom-switch {
    label {
      font-size: 0.875em;
      padding-top: 2px;
      vertical-align: middle;
    }
  }
}

.observation-process-summary {
  .card-header {
    > span {
      cursor: pointer;
    }
  }
  .summary-footer {
    flex-wrap: nowrap !important;
    > button {
      min-width: 120px;
    }
    > button:first-child {
      margin-right: auto;
    }
  }
}

.prp-badge {
  cursor: pointer;
  margin-bottom: 0 !important;
}
.prp-modal {
  button {
    margin-left: auto;
    width: fit-content;
  }
}

.ucr-relation-header {
  display: flex;
  flex-direction: column;
  height: 19%;
  padding: 5px;
  .ucr-relation-header-title {
    display: flex;
    align-items: center;
    margin-left: -5px;
  }
  h5 {
    color: #ff6f00;
    display: inline-block;
    font-weight: bold;
    margin: 0.25rem 0;
  }
  h6 {
    color: #ff6f00;
    margin: 0.25rem 0;

    &.normal {
      font-weight: normal;
    }
  }
  .info-label {
    font-size: 0.95em;
    p {
      margin: 0 5px;
    }
  }
}

.reset-observation-icon {
  color: #232f3e;
  &:hover {
    color: darken(#232f3e, 25%);
  }
}

.clickable {
  cursor: pointer;
}

.observation-exempted-modal {
  .exempted-modal-body {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    .exemption-item {
      border-bottom: 1px solid #eceeef;
      margin-bottom: 10px;
    }
  }
}

.loading-container {
  display: flex;
  height: 100%;
  width: 100%;

  .spinner-border {
    margin: auto;
  }
}

.styled-image-carousel {
  display: flex;
  height: 100%;
  background-color: #0e0e0e;
  border-radius: 3px;
  padding: 2px;
  width: 100%;
}

.image-slider {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  color: #c4c4c4;

  -moz-user-select:none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .slider-up-button {
    display: flex;
    height: calc(10% - 10px);
    width: calc(100% - 10px);
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;

    svg {
      margin: auto;
    }

    &:hover {
      background-color: #1a1a1a;
    }
  }

  .slider-down-button {
    display: flex;
    height: calc(10% - 10px);
    width: calc(100% - 10px);
    border-radius: 5px;
    cursor: pointer;
    margin: auto 5px 5px 5px;

    svg {
      margin: auto;
    }

    &:hover {
      background-color: #1a1a1a;
    }
  }

  .slider-thumbnail {
    display: flex;
    height: calc(20% - 4px);
    width: calc(100% - 4px);
    margin: 2px;
    cursor: pointer;
    border: 1px solid #5a5a5a;
    background-color: #272727;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
      border: 1px solid #a6a6a6;
    }

    img {
      max-height: 100%;
      width: 100%;
      margin: auto;

      object-fit: contain;
    }
  }

  .selected {
    border: 1px solid #ff6f00;
  }
}

.image-right-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  justify-content: center;
  position: relative;

  .editor-container {
    display: flex;
    flex-direction: row;

    span {
      color: white;
    }

    .slider-container {
      grid-area: slider;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      .slider {
        width: 100%;
        cursor: pointer;
      }

      input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
      }

      /***** Track Styles *****/
      /***** Chrome, Safari, Opera, and Edge Chromium *****/
      input[type="range"]::-webkit-slider-runnable-track {
        background: white;
        height: 0.2rem;
      }

      /******** Firefox ********/
      input[type="range"]::-moz-range-track {
        background: white;
        height: 0.2rem;
      }

      /***** Thumb Styles *****/
      /***** Chrome, Safari, Opera, and Edge Chromium *****/
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -12px; /* Centers thumb on the track */
        background-color: #007bff;
        height: 1.7rem;
        width: 1rem;
      }

      /***** Thumb Styles *****/
      /***** Firefox *****/
      input[type="range"]::-moz-range-thumb {
        border: none; /*Removes extra border that FF applies*/
        border-radius: 0; /*Removes default border-radius that FF applies*/
        background-color: #007bff;
        height: 1.7rem;
        width: 1rem;
      }
    }
    .close-btn {
      display: flex;
      position: absolute;
      top: 0px;
      right: 0px;
      color: #c4c4c4;
      cursor: pointer;

      &:hover {
        color: #ffffff;
      }
    }

    .header-btn {
      margin: auto;
      width: 10%;
      display: flex;
      margin-right: 10px;

      .btn {
        width: 100%;
      }
    }
  }

  .container-header {
    display: flex;
    height: 17.5%;
    width: 100%;

    .header-plate-image {
      display: flex;
      height: calc(60%);
      width: calc(33% - 10px);
      margin: auto 5px auto 5px;
      background-color: #272727;
      border-radius: 5px;
      overflow: hidden;

      img {
        margin: 0 auto;
      }
    }

    .header-plate-field {
      display: flex;
      height: calc(60%);
      width: calc(52% - 10px);
      margin: auto 5px auto 5px;
      position: relative;

      .header-plate-field-country {
        display: flex;
        width: calc(100% / 3);
        height: 100%;
        color: #ffffff;
        background-color: #31448b;
        border-radius: 5px 0 0 5px;

        p {
          margin: auto;
          font-size: 1.125em;
          font-weight: bold;
        }

        .form-group {
          margin: auto;
        }
      }

      .header-plate-field-number {
        display: flex;
        width: calc(100% / 3 * 1.5);
        height: 100%;
        color: #212529;
        background-color: #ffffff;
        border: 3px solid #31448b;
        border-left: 0;

        &.enabled {
          background-color: #fdfce5;
        }

        p {
          margin: auto;
          font-size: 1.125em;
          font-weight: bold;
        }

        .form-group {
          margin: auto;
        }
      }

      .header-duplicate-code {
        display: flex;
        width: calc(100% / 3 * .5);
        height: 100%;
        color: #212529;
        background-color: #ffffff;
        border-radius: 0px 5px 5px 0px;
        border: 3px solid #31448b;
        border-left: 0;

        &.enabled {
          background-color: #fdfce5;
        }

        p {
          margin: auto;
          font-size: 1.125em;
          font-weight: bold;
        }

        .form-group {
          margin: auto;
        }
      }

      input.license-plate-input:enabled,
      input.license-plate-input:focus {
        font-size: 1.125em;
        font-weight: bold;
        text-align: center;
        border: 0;
        color: #212529;
        margin-top: 1px;
        background-color: #fdfce5;
        box-shadow: none;
      }

      input.duplicate-code-input:enabled,
      input.duplicate-code-input:focus {
        font-size: 1.125em;
        font-weight: bold;
        text-align: center;
        border: 0;
        color: #212529;
        margin-top: 1px;
        background-color: #fdfce5;
        box-shadow: none;
        padding: 0;
      }

      input.duplicate-code-input::-webkit-outer-spin-button,
      input.duplicate-code-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input.duplicate-code-input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
      }

      input.duplicate-code-input.is-invalid {
        background-color: #fdc6ca;
        background-image: none;
      }

      select.license-country-code:enabled,
      select.license-country-code:focus {
        font-size: 1.125em;
        font-weight: bold;
        color: #FFFFFF;
        background-color: #31448b;
        border: 0;
        box-shadow: none;
        text-align: center;
        border-radius: 0;

        option {
          color: #212529;
          background: #ffffff;
          font-size: 1em;
          font-family: "MuseoSans", sans-serif;
        }
      }

      .dupl-code-floating-label {
        position: absolute;
        left: 87%;
        font-size: x-small;
      }
    }

    .header-buttons {
      display: flex;
      height: calc(100%);
      width: calc(28% - 10px);
      margin: auto 5px auto 5px;
      color: #007bff;

      .header-edit-button {
        cursor: pointer;
        display: flex;
        margin: auto auto auto 20px;

        :hover {
          color: #0067d6;
        }
      }

      .header-save-button {
        cursor: pointer;
        display: flex;
        margin: auto 0;

        &:hover {
          color: #0067d6;
        }

        svg {
          border-radius: 5px;

          &:hover {
            background-color: #1a1a1a;
          }
        }
      }

      .header-cancel-button {
        cursor: pointer;
        display: flex;
        margin: auto 0 auto 5px;
        color: rgb(255, 255, 255);

        &:hover {
          color: #cccccc;
        }

        svg {
          border-radius: 5px;

          &:hover {
            background-color: #1a1a1a;
          }
        }
      }

      .spinner-border {
        margin: auto;
      }
    }

    &.small {
      height: 11%;
      width: 75%;
    }
  }

  .container-body {
    display: flex;
    height: calc(82.5% - 10px);
    width: calc(100% - 10px);
    margin: 5px;
    background-color: #272727;
    border-radius: 5px;
    color: #272727;
    position: relative;
    overflow: hidden;

    .react-transform-wrapper {
      height: 100%;
      width: 100%;

      .react-transform-component {
        height: 100%;
        width: 100%;

        .video-react {
          margin-left: 132px;
        }
      }
    }

    img {
      cursor: pointer;
      max-height: 100%;
      width: 100%;
      margin: auto;

      object-fit: contain;
    }

    .video-react {
      margin-left: 77px;

      &.full-screen {
        margin-left: 132px;
      }
    }

    .container-toolbar {
      position: absolute;
      display: flex;
      top: 0;
      width: 100%;
      justify-content: center;
      z-index: 2;

      &.small {
        justify-content: end;
        padding-right: 15%;
      }

      .toolbar-btn {
        display: flex;
        margin: 10px;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        color: #c4c4c4;

        &:hover {
          background-color: #1a1a1a;
        }

        &.small {
          margin: 5px;
          padding: 0px;
        }

        svg {
          margin: auto;
        }

        .custom-svg-icon {
          width: 40px;
          height: 40px;
          margin: auto;
        }
      }
    }

    .container-button {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      width: 15%;
      border-radius: 5px;
      cursor: pointer;
      color: #c4c4c4;

      z-index: 2;

      &:hover {
        background: linear-gradient(to right, rgba(26, 26, 26, 1), rgba(26, 26, 26, 0));
      }

      svg {
        margin: auto;
      }
    }

    .right-button {
      right: 0;
      left: auto;

      &:hover {
        background: linear-gradient(to right, rgba(26, 26, 26, 0), rgba(26, 26, 26, 1));
      }
    }
  }
}


.ucr-pipeline-results-modal {
  display: block !important;

  .modal-dialog{
    overflow-y: initial !important
  }

  .ucr-pipeline-results-body {
    background: #FFFFFF;
    height: 50vh;
    font-size: 0.85em;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .row-header {
      border-bottom: 1px solid #e96c31;
      color: #979797;
      background: #FFFFFF;
    }

    .row-content {
      border-bottom: 1px solid #ced4da;
      color: #373a3c;
    }
  }
}

.pipeline-failed {
  width: 707px;
  margin-left: calc(29%);
}

td.use-case-img-row {
  padding-top: 0;
  padding-bottom: 0;
}

.use-case-img-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.use-case-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
