// Settings
.cop_settings {
  display: flex;
  height: 100%;
  background-color: #f7f8fb;
}

// Exemptions
.cop_exemptions {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.submenu {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 1 / 6);
    height: 100%;

    .left-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;

      h4 {
        margin: 15px;
        color: #777777;
        font-weight: 600;
      }

      button {
        margin: 15px;
        margin-left: auto;
      }
    }

    .left-panel-body {
      display: flex;

      width: calc(100% - 20px);
      height: calc(100% - 20px);

      .loading-box {
        margin: auto;
      }
      .submenu {
        p {
          margin-left: 15px;
        }
      }
    }
  }

  .right-panel {
    border: 1px solid #d7d7d8;
    display: flex;
    background: #ffffff;
    flex-direction: column;

    width: calc(100% * 5 / 6 - 30px);
    height: calc(100% - 20px);
    margin-top: 20px;

    .right-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;
      margin: 15px;

      h5 {
        color: #000000;
        font-weight: 800;
      }

      .new-type-btn {
        margin-left: auto;
      }
    }

    .right-panel-body {
      display: flex;
      height: calc(100% - 20px);
      margin: 0px 15px 15px 15px;

      .table {
        font-size: 14px;
        .col-id {
          width: 100px;
        }

        .col-global {
          width: 120px;
        }

        .actions-cell {
          text-align: right;
          i {
            margin: 5px;
            color: #b3b3b3;

            &:hover {
              color: #528ff0;
            }

            &.disabled {
              color: #dddddd;
              cursor: default;
            }

          }
        }
        .badges-cell {
          .badge {
            color: #ffffff;
            width: 100px;
            text-transform: uppercase;
            font-weight: 600;

            &.badge-by-primary {
              background: #0049c0;
              border-color: #0049c0;
            }

            &.badge-by-secondary {
              background: #884fc5;
              border-color: #884fc5;
            }

            &.badge-by-approved {
              background: #26c840;
              border-color: #26c840;
            }

            &.badge-by-denied {
              background: #c7434c;
              border-color: #c7434c;
            }

            &.badge-by-null {
              display: none;
            }
          }
        }
      }
    }
  }
}

.exemption-type-modal {
  .modal-header {
    border-bottom: none;
    .modal-title {
      &.h4 {
        color: #e5733e;
      }
    }
  }

  .modal-body {
    .container {
      .row {
        width: 100%;

        .label {
          margin: 8px 0 0 0;
        }

        .field {
          margin: 0;

          label {
            margin: 0;
          }

          input, select {
            width: 100%;
          }
        }

        &.name {
          display: block;

          .field,
          .label {
            width: 100%;
          }
        }

        &.time-picker {
          input {
            width: 88%;
          }
        }
      }

      .controls {
        width: 100%;
        margin: 10px 0;

        .button-group {
          margin: auto;

          .btn-outline-by-dark {
            position: absolute;
            right: 20px;
          }
        }
      }
    }
  }

}

.exemption-modal {
  .modal-header {
    border-bottom: none;
    .modal-title {
      &.h4 {
        color: #e5733e;
      }
    }
  }

  .modal-content {
    display: flex;
    width: 60%;

    form {
      .controls {
        width: 100%;
        margin: 10px 0;

        .hidden {

        }

        .button-group {
          margin: auto;
          padding-left: 32px;

          .btn-outline-by-dark {
            position: absolute;
            right: 50px;
          }
        }
      }

      .badges-cell {
        display: flex;
        justify-content: center;
        width: calc(100% + 40px);

        .input-group-badge {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          color: #ffffff;

          &.global {
            background: #0049c0;
          }

          &.use-case {
            background: #884fc5;
          }
        }
      }
    }
  }
}

// sources
.cop-uc-sources {

  .body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .details-left-panel {
      display: flex;
      flex-direction: column;
      width: 690px;
      height: 100%;
      margin: 0px;
      background-color: #FFFFFF;
      border: 1px solid #e5e5e5;
      padding: 10px 5px 0px 5px;
    }

    .details-block-panel {
      width: calc(55% - 10px);
      height: 100%;
      margin: 0px 0px 0px 10px;
      background-color: #FFFFFF;
      border: 1px solid #e5e5e5;
      padding: 10px 10px 0px 10px;

      .row {
        width: 100%;

        &.sectionLabel {
          min-height: 25px;
        }

        &.orangeLabel {
          min-height: 25px;
          color: #f36c21;
        }


        &.header {
          border-bottom: 1px solid #ef7f41;
          padding-bottom: 5px;

          .label {
            width: 10%
          }

          .field {
            width: 80%;
          }
        }

        .field {
          .small-input {
            width: 20%;
          }

          .left-label {
            width: 50px;
          }

          .middle-label {
            width: 50px;
            margin-left: 25px;
          }

          .right-label {
            max-width: 150px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

// Pipelines
.cop-uc-pipelines {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .details-left-panel {
      display: flex;
      flex-direction: column;
      width: 12%;
      height: 100%;
      margin: 0px;
      background-color: #FFFFFF;
      border: 1px solid #e5e5e5;
      padding: 10px 5px 0px 5px;

      .new-pipeline-btn {
        width: 90%;
        margin: 0 auto;
      }

      .pipeline-menu {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        div {
          p {
            margin-left: 10px;
          }
        }
      }
    }

    .details-right-panel {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0px;
      padding: 10px 10px 0px 10px;
      background-color: #FFFFFF;
      border: 1px solid #e5e5e5;
      border-left: none;
      width: 33%;

      // Pipeline processors
      .cop-ucp-details {
        height: 95%;

        .header {
          height: 10%;
        }

        .cop-ucp-edit {
          padding-left: 20px;
          color: #b3b3b3;

          &:hover {
            color: #528ff0;
            cursor: pointer;
          }
        }

        .new-pipprocessor-btn {
          float: right;
          padding: 5px 30px 5px 30px;
        }

        .droppable-container {
          padding-top: 20px;
          height: 95%;

          div[data-rbd-droppable-id] {
            overflow-y: auto;
            width: 100%;
            height: 95%;
            max-height: 700px;
          }
          .card {
            border: none;
            height: 65px;
            background-color: transparent;
            cursor: default;

            i {
              &.direction {
                height: 16px;
                position: absolute;

                .fi-arrow-down {
                  color: #e5733e;
                  stroke-width: 4;
                  margin: 2.4em 0 2.6em 12em;
                }
              }
            }
          }

          .pipeline-processor-card {
            border: 1px solid #007BFF;
            border-radius: 7px;
            display: flex;
            flex-direction: row;
            margin-left: 30px;
            cursor: default;

            &:hover {
              border: 1px solid #e5733e;
            }

            &.selected {
              border-radius: 7px;
              background-color: #e6f0ff;
            }

            .io-ios-menu-icon {
              margin-left: -30px;
              margin-top: 8px;
            }
          }

          .processor-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;

            .processor-class-name {
              width: 55%;
              font-weight: 600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .processor-type {
              line-height: 17px;
              font-size: 14px;
              width: 32%;
              color: #909393;
              text-wrap: balance;
            }

            .actions-cell {
              gap: 10px;
              .info-pipprocessor {
                color: #007BFF;
                border-color: #007BFF;
                width: 30px;
                cursor: pointer;
              }
              .fa-trash-alt{
                fill: #b3b3b3;

                &:hover {
                  fill: #777777;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .cop-ucp-footer {
        text-align: right;
        height: 5%;

        .cop-ucp-delete {
          cursor: pointer;
          color: #c7444d;
        }
      }
    }

    .details-block-panel {
      width: calc(55% - 10px);
      height: 100%;
      margin: 0px 0px 0px 10px;
      background-color: #FFFFFF;
      border: 1px solid #e5e5e5;
      padding: 10px 10px 0px 10px;

      .VehicleInfoDecorator,
      .OffenseDecorator {
        .field {
          .box-col {
            margin-left: 0;

            p {
              margin-left: 5px;
            }
          }
        }
      }
      .FollowUpDecorator {
        .follow_up_rule {
          min-height: 25px;
        }

        .content {
          max-height: 680px;
          overflow-x: auto;
        }
      }

      .RailwayViolationChecker,
      .ObjectTypeChecker{
        .content {
          max-height: 680px;
          overflow-x: auto;
        }
      }

      .OffenseDecorator {
        .header {
          height: 60px;
        }

        .content {
          max-height: 680px;
          overflow-x: auto;
        }

        .criteria {
          min-height: 25px;
        }

        .dropdown {
          input.offenseSelector {
            width: 92%;
            font-size: inherit;

            &:focus {
              box-shadow: none;
              outline: 1px auto rgb(16, 16, 16);
              outline-offset: 0px;
            }
          }
        }
      }

      .FollowUpDecorator {
        .row {
          .field {
            .small-input {
              width: 10%;
            }
            .left-label {
              width: 120px;
            }
            .right-label {
              max-width: 450px;
              margin-left: 10px;
              display: inline-flex;
              vertical-align: middle;
            }
          }
        }
      }


      .row {
        width: 100%;

        &.pardonTime {
          .field {
            display: block;
          }
        }

        &.sectionLabel {
          min-height: 25px;
        }

        &.orangeLabel {
          min-height: 25px;
          color: #f36c21;
        }

        .recipient {
          padding-bottom: 10px;

          button {
            border: none;
            border-radius: 0;
            padding: 0;
          }

          input {
            width: 80%;
          }

          i {
            &:hover {
              cursor: pointer;
            }
            &.add {
              color: #007bff;
            }

            &.remove {
              color: #030608;
            }
          }
        }

        &.header {
          border-bottom: 1px solid #ef7f41;
          padding-bottom: 5px;

          .label {
            width: 10%
          }

          .field {
            width: 40%;
          }

          .extra {
            width: calc(50% - 20px);

            .btn {
              width: 100px;
            }

            .controls {
              margin: 0;
            }
          }
        }

        .field {
          .small-input {
            width: 20%;
          }

          .left-label {
            width: 50px;
          }

          .middle-label {
            width: 50px;
            margin-left: 25px;
          }

          .right-label {
            max-width: 150px;
            margin-left: 10px;
          }
        }
      }
      div.providers {
        div.box-col {
          margin-left: 0px;
        }
      }

      .alert-switch {
        width: 100%;
        font-weight: normal;
        margin-bottom: 0;
      }
    }
  }
}

.available-processors-container {
  overflow-y: scroll;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  .processor-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 0;
    border: 1px solid #007BFF;
    border-radius: 7px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      border: 1px solid #e5733e;
    }

    .processor-class-name {
      width: 60%;
      font-weight: 600;
    }

    .processor-type {
      width: 30%;
      color: #909393;
    }

    .info-pipprocessor {
      width: 10%;
      color: #007BFF;
      border-color: #007BFF;
      width: 20px;
    }
  }
}

.blurring-offset-modal {
  .modal-header {
    border-bottom: none;
    .modal-title {
      &.h4 {
        color: #e5733e;
      }
    }
  }

  .modal-content {
    display: flex;
  }

  .left-side {
    display: flex;
    width: 50%;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }

  .right-side {
    display: flex;
    width: 50%;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }

  .comparison-container {
    display: flex;
    flex-direction: row;
  }

  .comparison-container .image-container {
    position: relative;
    margin: 10px;
  }

  .comparison-container .comparison-image {
    max-width: 100%;
    max-height: calc(100vh - 400px);
  }
  .comparison-container .controls {
    display: flex;
  }

  .comparison-container .position-grid button, .comparison-container .size-grid button {
    display: block;
    width: 30px !important;
    height: 30px !important;
    border-radius: 3px;
    padding: 0;
  }

  .position-grid {
    display: grid;
    grid-template-columns: repeat(3, 30px);
    grid-template-rows: repeat(3, 30px);
    gap: 5px;
  }

  .size-grid {
    display: grid;
    grid-template-columns: repeat(1, 30px);
    grid-template-rows: repeat(2, 30px);
    gap: 5px;
  }

  .grid-cell {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cop-shape-files-container {
  .field {
    width: auto !important;
  }
  .label {
    width: auto !important;
  }
}
